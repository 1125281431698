/* You can add global styles to this file, and also import other style files */
@import '~ngx-toastr/toastr.css';
.btn {
  /* border-radius: 30px !important;*/
  /*display: block;*/
  /*width: 90%;*/
  border: none;
  cursor: pointer;
}

.btn-primary {
  background-color: #ED1B24;
  color: white;
  margin: auto;
  margin-top: 15px;
}
.btn-primary-outline {
  border: 1px solid #ED1B24;
  color: #ED1B24;
  margin: auto;
  margin-top: 15px;
}

.btn-secondary {
  background-color: white;
  color: #ED1B24;
  margin: auto;
  margin-top: 15px;
}
.btn-primary-outline {
  border: 1px solid white;
  color: white;
  margin: auto;
  margin-top: 15px;
}


/* Toaster Styling */

/* #toast-container > div {
  opacity:1;
  position: relative;
  top: 2%;
  right: 0;
  background-color: aqua;
} */


/* #toaster-container > div {
opacity: 1;
}
.toast {
  font-size: initial !important;
  border: initial !important;
  backdrop-filter: blur(0) !important;
}
.toast-success {
  background-color: #51A351 !important;
  color: #fff;
  max-width: 15rem;
  border-radius: 8px;
  padding: 24px 20px;
  margin-bottom: 1.4rem !important;
}
.toast-error {
  background-color: #BD362F !important;
  color: #fff;
  max-width: 15rem;
  border-radius: 8px;
  padding: 24px 20px;
  margin-bottom: 1.4rem !important;
}
.toast-info {
  background-color: #2F96B4 !important;
  color: #fff;
  max-width: 15rem;
  border-radius: 8px;
  padding: 24px 20px;
  margin-bottom: 1.4rem !important;
}
.toast-warning {
  background-color: #F89406 !important;
  color: #fff;
  max-width: 15rem;
  border-radius: 8px;
  padding: 24px 20px;
  margin-bottom: 1.4rem !important;
} */